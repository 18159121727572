<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="password"
        name="password"
        label="新密码"
        placeholder="请输入，6-20位数字或字母"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  props: {
  },
  setup () {
    const req = inject('req')
    const Cookies = inject('Cookies')
    const state = reactive({
      password: '',
      userId: Cookies.get('userId')
    })
    const onSubmit = (values) => {
      req.post('/user.updatePassword', {
        ...values,
        userId: state.userId
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>
<style scoped>
</style>
